<template>
	<div class="garden-list-page">
		<div class="search-box">
			<a-row class="search-row">
				<a-col :span="4">
					<a-row class="">
						<a-col :span="6" class="label-box">类型：</a-col>
						<a-col :span="18" class="value-box">
							<a-select v-model="park_type_id" class="a-select" placeholder="请选择" @change="searchList">
								<a-select-option :value="item.park_type_id" v-for="(item,index) in ParkTypeList" :key="item.park_type_id">
									{{item.park_type_name}}
								</a-select-option>
							  </a-select>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="4">
					<a-row class="">
						<a-col :span="6" class="label-box">区域：</a-col>
						<a-col :span="18" class="value-box">
							  <a-cascader
								v-model="areaArr"
								class="cascader"
								:field-names="{ label: 'name', value: 'name', children: 'districts' }"
								:load-data="loadSsq"
								:options="areaList"
								placeholder="请选择区域"
								@change="ssqChange"
							/>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="4">
					<a-row class="">
						<a-col :span="6" class="label-box">价格：</a-col>
						<a-col :span="18" class="value-box">
							<a-select v-model="price_id" class="a-select" placeholder="请选择" @change="searchList">
								<a-select-option :value="item.id" v-for="(item,index) in priceList" :key="item.id">
									{{item.label}}
								</a-select-option>
							  </a-select>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="4">
					<a-row class="">
						<a-col :span="6" class="label-box">名称：</a-col>
						<a-col :span="18" class="value-box">
							<a-input placeholder="请输入" v-model="park_name"/>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="4">
					<a-row class="">
						<a-col :span="6" class="label-box">状态：</a-col>
						<a-col :span="18" class="value-box">
							<a-select v-model="status" class="a-select" placeholder="请选择">
								<a-select-option :value="1">
								 启用
								</a-select-option>
								<a-select-option :value="-1">
								  关闭
								</a-select-option>
							  </a-select>
						</a-col>
					</a-row>
				</a-col>
				<a-col class="btn-box">
					<a-button type="default" @click="resetTiao">重置</a-button>
					<a-button type="primary" @click="searchList">查询</a-button>
				</a-col>
			</a-row>
			
		</div>
		<div class="color-block"></div>
		<div class="list-table-box">
			<div class="top-box">
				<div class="control-box">
					<a-button type="primary" @click="toDetail(0)">
						<a-icon type="plus-circle" />
						添加
					</a-button>
				</div>
				<div class="tongji-box">
					<div class="item">
						<div class="tl"></div>
					</div>
				</div>
			</div>
			<a-table :rowKey="(item,index) => {return index}" 
				:loading="dataLoading" 
				:columns="columns"
				:data-source="list"
				:pagination="{
					showTotal: total => `共 ${total} 条园区记录，总浏览量${sum_view}`,
					current: page.current, 
					total: page.total}"
				@change="listChange"
				:bordered="true">
				<template slot="header_img" slot-scope="index, item">
					<div class="head-img-box">
						<img :src="$imglink+item.header_img" >
					</div>
				</template>
				<template slot="is_show" slot-scope="index, item">
					<div class="qiy" :class="{act : item.is_show == 1}" @click="toggleShow(item.park_id,item.is_show)">
						{{item.is_show == 1 ? '启用' : '关闭'}}
					</div>
				</template>
				<template slot="is_recommend" slot-scope="index, item">
					<a-switch :style="{'pointer-events': reIng ? 'none' : 'auto'}" :checked="item.is_recommend == 1" @change="recoChange(item.park_id,item.is_recommend)" />
				</template>
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="toEdit(item)">编辑</a-button>
						<a-button size="small" type="primary" @click="showMiniMoadl(item)">小程序</a-button>
						<a-popconfirm title="确认删除该园区" ok-text="是" cancel-text="否" @confirm="deleteItem(item)">
							<a-button size="small" type="danger">删除</a-button>
						</a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>

		<a-modal class="coppy-modal" v-model="miniModalShow" ok-text="确认" cancel-text="关闭"  @ok="okCoppy">
		<div style="padding-top: 30px"></div>
			<a-row style="margin-bottom: 20px">
				<a-col :span="4" style="text-align:right;line-height: 33px">Appid：</a-col>
				<a-col :span="17">
					<a-input readOnly v-model="appid" id="appid" />
				</a-col>
				<a-col :span="3" style="display:flex;justify-content: flex-end;"><a-button type="primary" @click="coppy('appid')">复制</a-button></a-col>
			</a-row>
			<a-row>
				<a-col :span="4" style="text-align:right;line-height: 33px">小程序路径：</a-col>
				<a-col :span="17">
					<a-input readOnly v-model="applink" id="applink" />
				</a-col>
				<a-col :span="3" style="display:flex;justify-content: flex-end;">
					<a-button type="primary" @click="coppy('applink')">复制</a-button>
				</a-col>
			</a-row>
		</a-modal>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data() {
			let columns = [{
				title: '类型',
				key: 'park_type_name',
				dataIndex: 'park_type_name'
			},{
				title: '展示图',
				dataIndex: 'header_img',
				width: '148px',
				scopedSlots: { customRender: 'header_img' }
			},{
				title: '名称',
				key: 'park_name',
				dataIndex: 'park_name'
			},{
				title: '行政地区',
				customRender: (text, record, index) => `${record.pro}>${record.city}>${record.area}`
			},{
				title: '价格',
				key: 'tag_name',
				dataIndex: 'tag_name',
				defaultSortOrder: 'descend',
				sortOrder: false,
				sorter: (a, b) => a.low_price - b.low_price,
				sortDirections: ['descend', 'ascend']
			},
			{
				title: '状态',
				dataIndex: 'is_show',
				scopedSlots: { customRender: 'is_show' },
				width: '65px'
			},
			{
				title: '热门推荐',
				key: 'is_recommend',
				scopedSlots: { customRender: 'is_recommend' },
					width: '105px'
			},{
				title: '操作',
				dataIndex: 'operation',
				scopedSlots: { customRender: 'operation' },
				width: '207px'
			}]
			let priceList = [
				{
					id: 1,
					low_price: 0,
					high_price: 1,
					label: '1元以下/(m².天)'
				},
				{
					id: 2,
					low_price: 1,
					high_price: 1.5,
					label: '1-1.5元/(m².天)'
				},
				{
					id: 3,
					low_price: 1.5,
					high_price: 2,
					label: '1.5-2元/(m².天)'
				},
				{
					id: 4,
					low_price: 2,
					high_price: 2.5,
					label: '2-2.5元/(m².天)'
				},
				{
					id: 5,
					low_price: 2.5,
					high_price: 3,
					label: '2.5-3元/(m².天)'
				},
				{
					id: 6,
					low_price: 3,
					high_price: 3.5,
					label: '3-3.5元/(m².天)'
				},
				{
					id: 7,
					low_price: 3.5,
					high_price: 4,
					label: '3.5-4元/(m².天)'
				},
				{
					id: 8,
					low_price: 4,
					high_price: 9999999,
					label: '4元以上/(m².天)'
				}
			];
			return {
				dataLoading: false,
				columns,
				list: [],
				page:{
					current: 1,
					total: 0
				},
				areaList: [],
				priceList,
				park_type_id: undefined,
				park_name: '',
				status: undefined,
				ParkTypeList:[],
				showIng: false,
				reIng: false,
				miniModalShow: false,
				linkQz:  '/PackageA/garden-detail/garden-detail?id=',
				applink:'',
				appid: 'wx215242bea5135599',
				copyId:'',
				areaArr: [],
				area_code: undefined,
				price_id: undefined,
				sum_view: 0,
			}
		},
		computed: {},
		created() {
			this.getParkTypeList()
			this.getParkList()
			this.getCitySelect()
		},
		mounted() {
			
		},
		methods:{
			searchList() {
				let t = this;
				t.page.current = 1
				t.getParkList()
				
			},
			getParkTypeList() {
				let t = this
				t.$post('ParkTypeList',{limit: 9999}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.ParkTypeList = data.list
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			getParkList() {
				let t = this
				let data ={
					park_type_id: t.park_type_id || '',
					park_name: t.park_name,
					is_show: t.status || '',
					price_id: t.price_id || '',
					area_code: t.area_code, 
					page: t.page.current
				}
				console.log(data)
				
				t.$post('ParkList',data).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data.list
						t.page.total = data.total
						t.sum_view = data.sum_view
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			toDetail(type) {
				this.$router.push({path: '/gardenDetail',query: {type: type}})
			},
			toEdit(item) {
				this.$router.push({path: '/gardenDetail', query:{id: item.park_id}})
			},
			deleteItem(item) {
				let t = this
				t.$post('parkDelete',{park_id: item.park_id}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success('删除成功',1.5)
					    t.searchList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			listChange(e) {
				this.page.current = e.current
				this.getParkList();
			},
			resetTiao() {
				this.park_type_id = undefined
				this.park_name = ''
				this.status = undefined
				// this.searchList()
			},
			toggleShow(id,is_show) {
				let t = this
				if(t.showIng) {
					return 
				}else{
					t.showIng = true
				}
				t.$post('parkFabu',{park_id: id}).then(res => {
					t.showIng = false
					let { code, data, msg } = res
					if (code == 0) {
						let str = is_show == 1 ? '已关闭' : '已启用'
						
						console.log(str)
 						t.$message.success(str,1.5)
					    t.getParkList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.showIng = false
				})
			},
			recoChange(id,is_show) {
				let t = this
				if(t.reIng) {
					return 
				}else{
					t.reIng = true
				}
				t.$post('parkRecommend',{park_id: id}).then(res => {
					t.reIng = false
					let { code, data, msg } = res
					if (code == 0) {
						let str = is_show == 1 ? '已关闭' : '已启用'
						console.log(str)
						t.$message.success(str,1.5)
					    t.getParkList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.reIng = false
				})
			},
			showMiniMoadl(item) {
				this.copyId = item.park_id
				this.applink = this.linkQz + item.park_id
				this.miniModalShow = true
			},
			coppy(ele) {
				let inputEle = document.getElementById(ele)
				inputEle.select()
				document.execCommand('Copy')
				this.$message.success('复制成功',1.2)
			},
			okCoppy() {
				this.applink = ''
				this.miniModalShow = false
			},
			getCitySelect() {
				let t = this
				t.$post('citySelect').then(res => {
					console.log('res')
					if(res.code == 0) {
						t.areaList  = res.data.map(item => {
							item.isLeaf = false
							item.level = 'city'
							item.adcode=item.code
							return item
						})
					}
				})
			} ,
			loadSsq(selectedOptions) {
				const targetOption = selectedOptions[selectedOptions.length - 1];
				targetOption.loading = true;
				let subdistrict = targetOption.level == 'province' ? 1 : targetOption.level == 'city' ? 2 : targetOption.level == 'district' ? 3 : 4;
				axios
					.get('https://restapi.amap.com/v3/config/district', {
						params: {
							keywords: targetOption.adcode,
							subdistrict: subdistrict,
							key: 'ce7404ac71c28a7893cae3ccc5517da0'
						}
					})
					.then(res => {
						let districts = res.data.districts[0].districts;
						for (let i = 0; i < districts.length; i++) {
							let item = districts[i];
							delete item.districts;
							item.isLeaf = item.level == 'district' || res.data.districts[0].adcode == 810000 || res.data.districts[0].adcode == 820000;
						}
						targetOption.districts = districts;
						targetOption.loading = false;
						this.areaList = [...this.areaList];
					})
					.catch(err => {
						console.log(err);
					});
			},
			ssqChange(e, opt) {
				console.log(opt)
				this.area_code =  opt && opt[1] ? opt[1].adcode : '';
				this.searchList()
			},
 		}
	}
</script>

<style lang="less">
	.head-img-box {
		width: 100%;
		max-height: 120px;
		img{
			width: 100%;
			height: 100%;
			display: block;
			object-fit: contain;
		}
	}
	
	.coppy-modal {
		.ant-modal {
			width: 700px!important;
		}
	}

	.top-box {
		display: flex;
		justify-content: space-between;
		.tongji-box {
			display: flex;
		}
	}
</style>
